import React from 'react';
import { Container, Box, Divider } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import WebAppBar from '../../WebAppBar';
import Footer from '../../Footer';

const BlogPostSkeleton = () => {
  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', py: 8 }}>
      <WebAppBar />
      <Container maxWidth="lg">
        {/* Header Section */}
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            mt: 4,
            mb: 8,
            textAlign: 'left',
          }}
        >
          {/* Text Content */}
          <Box flex={1}>
            <Skeleton variant="text" width="80%" height={60} sx={{ fontSize: 'clamp(2rem, 5vw, 3rem)' }} />
            <Skeleton variant="text" width="60%" height={40} sx={{ mt: 2 }} />
            <Skeleton variant="text" width="40%" height={30} sx={{ mt: 2 }} />
          </Box>

          {/* Image Placeholder */}
          <Box
            sx={{
              backgroundColor: 'grey.300',
              borderRadius: '15px',
              width: '100%',
              height: { xs: '150px', md: '200px' },
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </Box>
        </Box>
      </Container>

      {/* Main Content Section */}
      <Container maxWidth="md">
        <Box my={4}>
          {[...Array(5)].map((_, index) => (
            <Box key={index} sx={{ mb: 4 }}>
              <Skeleton variant="text" width="100%" height={30} />
              <Skeleton variant="text" width="80%" height={30} sx={{ mt: 1 }} />
              <Skeleton variant="text" width="60%" height={30} sx={{ mt: 1 }} />
            </Box>
          ))}
        </Box>

        {/* Divider */}
        <Divider sx={{ my: 4 }} />

        {/* Share Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            mt: 2,
          }}
        >
          <Skeleton variant="text" width="20%" height={30} />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Skeleton variant="rectangular" width={120} height={40} />
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="circular" width={40} height={40} />
          </Box>
        </Box>
      </Container>

      {/* Recent Posts Section */}
      <Container maxWidth="lg">
        <Box my={8}>
          <Skeleton variant="rectangular" width="100%" height={200} />
        </Box>
      </Container>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default BlogPostSkeleton;