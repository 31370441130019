import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Container, Typography, Box, Button, IconButton, Divider, Link } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import CTASection from '../CTASection';
import WebAppBar from '../WebAppBar';
import RecentPosts from './sections/RecentSection';
import BlogPostSkeleton from './skeleton/BlogPostSkeleton';
import Footer from '../Footer';
import { styled } from '@mui/material/styles';

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 'clamp(1rem, 5vw, 1.5rem)',
  marginTop: "30px",
  fontWeight: 600,
  color: theme.palette.primary.main,
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  variant: 'body1',
  marginTop: "15px",
  color: theme.palette.text.primary,
}));

const SideHeading = styled(Typography)(({ theme }) => ({
  variant: 'h6',
  marginTop: "15px",
  fontWeight: 600,
  color: theme.palette.text.secondary,
}));

const Li = styled('li')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [buttonText, setButtonText] = useState('Copy the link');
  const blogUrl = window.location.href;

  useEffect(() => {
    if (!slug) return;

    const fetchPost = async () => {
        setLoading(true); 
        try {
            const response = await fetch(`https://api.joblixx.com/v1/blog/${slug}`, {
                headers: {
                    'X-CSRF-Token': process.env.REACT_APP_CSRF_TOKEN,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                window.location.href = '/404'; 
                return; 
            }

            const data = await response.json();
            setPost(data[0]); 
        } catch (error) {
            setError(error.message);
            console.error('Error fetching blog post:', error);
            // Redirect to error page if fetch fails
            if (error instanceof TypeError) {
                window.location.href = '/500';
            }
        } finally {
            setLoading(false); 
        }
    };

    fetchPost();

  }, [slug]);

  const handleClick = () => {
    navigator.clipboard.writeText(blogUrl);
    setButtonText('Copied');
    setTimeout(() => {
      setButtonText('Copy the link');
    }, 2000);
  };

  if (loading) return <BlogPostSkeleton />;
  if (error) return <div>Error: {error}</div>;
  if (!post) return <div>Post not found</div>;

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', py: 8 }}>
      <Helmet>
        <title>{post.seo_title}</title>
        <meta name="description" content={post.seo_description}/>
      </Helmet>
      <WebAppBar />
      <Container maxWidth="lg">
        <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} 
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            mt: 4,
            mb: 8,
            textAlign: 'left',
          }}>
          <Box flex={1}>
            <Typography variant="h2" fontSize={'clamp(2rem, 5vw, 3rem)'} gutterBottom>
              {post.title}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {post.description}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              By {post.author} | {new Date(post.published_at).toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'})}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundImage: `url(${post.cover_image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              color: 'primary.contrastText',
              borderRadius: '15px',
              width: '100%',
              height: '200px',
              py: 8,
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          />
        </Box>
      </Container>

      <Container maxWidth="md">
        <Box my={4}>
          {post.content.content.map((block, index) => (
            <ContentBlock key={index} block={block} />
          ))}
        </Box>
        <Divider gutterBottom/>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'space-between', mt: 2}}>
          <Typography variant="h6" sx={{ mb: 2 }}>Share this blog</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<ContentCopyIcon />}
              onClick={handleClick}
            >
              {buttonText}
            </Button>
            <IconButton
              color="primary"
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(blogUrl)}`}
              target="_blank"
              aria-label="Share on LinkedIn"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              color="primary"
              href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(blogUrl)}`}
              target="_blank"
              aria-label="Share on Twitter"
            >
              <TwitterIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
      <Container maxWidth={'lg'}>
        <RecentPosts />
        <Box my={8}>
          <CTASection />
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

const parseTextWithLinks = (text, links = []) => {
  // Split text by markdown-style links like [text](url)
  const parts = text.split(/(\[.*?\]\(.*?\))/g);

  return parts.map((part, index) => {
    // Check if this part is a markdown link
    const linkMatch = part.match(/\[(.*?)\]\((.*?)\)/);
    if (linkMatch) {
      const [_, linkText, linkUrl] = linkMatch;
      return (
        <Link key={index} href={linkUrl} target="_blank" color="inherit">
          {linkText}
        </Link>
      );
    }

    // Handle bold text and regular text
    return part.split('**').map((textPart, i) => (
      <React.Fragment key={`${index}-${i}`}>
        {i % 2 === 1 ? <strong>{textPart}</strong> : textPart}
      </React.Fragment>
    ));
  });
};

const ContentBlock = ({ block }) => {
  switch (block.type) {
    case 'heading':
      return (
        <Heading variant={`h${block.level}`} id={block.id}>
          {block.text}
        </Heading>
      );

    case 'paragraph':
      return (
        <Paragraph>
          {parseTextWithLinks(block.text, block.links)}
        </Paragraph>
      );

    case 'list':
      return (
        <Paragraph>
          <ul>
            {block.items.map((item, i) => (
              <Li key={i}>
                {parseTextWithLinks(item.text)}
              </Li>
            ))}
          </ul>
        </Paragraph>
      );

    case 'image':
      return (
        <Box sx={{ my: 4 }}>
          <img 
            src={block.url} 
            alt={block.alt} 
            style={{ 
              width: '100%', 
              borderRadius: '15px',
              marginBottom: '8px'
            }}
          />
          {block.caption && (
            <Typography variant="caption" color="text.secondary">
              {block.caption}
            </Typography>
          )}
        </Box>
      );

    default:
      return null;
  }
};

export default BlogPost;