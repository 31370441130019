import React from 'react';
import { Card, CardContent, Skeleton, Box } from '@mui/material';

const BlogCardSkeleton = () => {
  return (
    <Card>
      <Skeleton variant="rectangular" width="100%" height={200} />
      <CardContent>
        <Box sx={{ pt: 2 }}>
          <Skeleton variant="text" width="60%" height={40} />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="80%" />
        </Box>
      </CardContent>
    </Card>
  );
};

export default BlogCardSkeleton;