import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import WebAppBar from '../../WebAppBar';

const HeroSection = () => {
  return (
    <Box
    sx={{
        background: 'url(https://joblixx-user-docs.blr1.cdn.digitaloceanspaces.com/web/mesh-bg-blog.png)',
        backgroundSize: 'cover',
        color: 'primary.contrastText',
        borderRadius: '15px',
        py: 8,
        mb: 2,
      }}
    >
        <WebAppBar />
        <Container maxWidth="lg">
            <Typography variant="h1" align="center" gutterBottom sx={{fontSize: 'clamp(2.5rem, 5vw, 4rem)', mt:8}}>
            Content to power your job search
            </Typography>
            <Typography variant="h6" align="center" paragraph sx={{fontSize: 'clamp(1rem, 3vw, 1.4rem)'}}>
            Discover the latest insights and trends in job search
            </Typography>
        </Container>
    </Box>
  );
};

export default HeroSection;