// Sitemap.js
import React, { useEffect, useMemo } from 'react';

const DOMAIN = 'https://www.joblixx.com';
const CACHE_KEY = 'sitemap_lastmod_date';

const urls = [
    { loc: '/', priority: 1.0, changefreq: 'daily' },
    { loc: '/#solution', priority: 0.8, changefreq: 'weekly' },
    { loc: '/#services', priority: 0.8, changefreq: 'weekly' },
    { loc: '/#testimonials', priority: 0.7, changefreq: 'weekly' },
    { loc: '/#faq', priority: 0.6, changefreq: 'weekly' },
    { loc: '/terms-and-conditions', priority: 0.5, changefreq: 'monthly' },
    { loc: '/privacy-policy', priority: 0.5, changefreq: 'monthly' },
    { loc: '/cookies-policy', priority: 0.5, changefreq: 'monthly' },
    { loc: '/plans', priority: 0.7, changefreq: 'weekly' },
    { loc: '/help-center', priority: 0.6, changefreq: 'weekly' },
    { loc: '/help-center/about-joblixx', priority: 0.6, changefreq: 'weekly' },
    { loc: '/help-center/getting-started', priority: 0.6, changefreq: 'weekly' },
    { loc: '/help-center/profile', priority: 0.6, changefreq: 'weekly' },
    { loc: '/help-center/job-matching', priority: 0.6, changefreq: 'weekly' },
    { loc: '/help-center/scheduler', priority: 0.6, changefreq: 'weekly' },
    { loc: '/help-center/settings', priority: 0.6, changefreq: 'weekly' },
];

const getLastModDate = () => {
    try {
        const cached = localStorage.getItem(CACHE_KEY);
        if (cached) {
            const { date, timestamp } = JSON.parse(cached);
            const now = new Date().getTime();
            const oneDayMs = 24 * 60 * 60 * 1000;
            
            // If the cached date is from today, use it
            if (now - timestamp < oneDayMs) {
                return date;
            }
        }
        
        // If no cache or cache is old, create new date
        const newDate = new Date().toISOString();
        localStorage.setItem(CACHE_KEY, JSON.stringify({
            date: newDate,
            timestamp: new Date().getTime()
        }));
        return newDate;
    } catch (error) {
        // If localStorage fails, just return current date
        return new Date().toISOString();
    }
};

const Sitemap = () => {
    const lastmod = useMemo(() => getLastModDate(), []);

    useEffect(() => {
        // Create a new XML document
        const xmlDoc = document.implementation.createDocument(null, null);
        
        // Create processing instruction for XML declaration
        const xmlPI = xmlDoc.createProcessingInstruction('xml', 'version="1.0" encoding="UTF-8"');
        document.firstChild?.remove();
        document.insertBefore(xmlPI, document.firstChild);
        
        // // Create processing instruction for XSL stylesheet
        // const xslPI = xmlDoc.createProcessingInstruction('xml-stylesheet', 'type="text/xsl" href="public/sitemap.xsl"');
        // document.insertBefore(xslPI, document.firstChild?.nextSibling || null);
    }, []);

    const xmlContent = `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
${urls.map(url => `  <url>
    <loc>${DOMAIN}${url.loc}</loc>
    <lastmod>${lastmod}</lastmod>
    <changefreq>${url.changefreq}</changefreq>
    <priority>${url.priority}</priority>
  </url>`).join('\n')}
</urlset>`;

    return (
        <div dangerouslySetInnerHTML={{ 
            __html: xmlContent 
        }} />
    );
};

export default Sitemap;