import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Alert } from '@mui/material';
import BlogCard from '../BlogCard';
import BlogCardSkeleton from '../skeleton/BlogCardSkeleton';

const AllPosts = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          `https://api.joblixx.com/v1/blog/recent-blogs`,
          {
            headers: {
              'X-CSRF-Token': process.env.REACT_APP_CSRF_TOKEN,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch recent posts: ${response.statusText}`);
        }

        const data = await response.json();
        setAllPosts(data);
      } catch (error) {
        console.error('Error fetching recent posts:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  // Show error message 
  if (error) {
    return (
      <Box my={8}>
        <Alert severity="error">
          Failed to load recent posts: {error}
        </Alert>
      </Box>
    );
  }


  return (
    <Box my={8}>
      <Typography variant="h3" gutterBottom sx={{mb:3}}>
        All Posts
      </Typography>
      <Grid container spacing={4}>
      {loading
          ? // Show skeleton loading 
            Array.from({ length: 6 }).map((_, index) => (
              <Grid item key={index} xs={12} md={4}>
                <BlogCardSkeleton />
              </Grid>
            ))
          : // Show actual blog cards 
            allPosts.slice(0, 9).map((post) => (
              <Grid item key={post.slug} xs={12} md={4}>
                <BlogCard post={post} />
              </Grid>
            ))}
      </Grid>
    </Box>
  );
};

export default AllPosts;