import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const BlogCard = ({ post, featured }) => {
  return (
    <Card
        component={Link}
        to={`/blog/${post.slug}`}
        sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            textDecoration: 'none',
            transition: 'transform 0.2s',
            '&:hover': {
            transform: 'scale(1.03)',
            },
        }}
        >
        <CardMedia
            component="img"
            height={featured ? 400 : 200}
            image={post.cover_image}
            alt={post.title}
        />
        <CardContent sx={{ flexGrow: 1 }}>
            <Typography gutterBottom variant={featured ? 'h4' : 'h5'} component="h2">
            {post.title}
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
            {post.excerpt.slice(0,55)}...
            </Typography>
            <Button
                component={Link}
                to={`/blog/${post.slug}`}
                variant="outlined"
                color="primary"
                endIcon={<ArrowForwardIcon />}
            >
                Read More
            </Button>
        </CardContent>
    </Card>
  );
};

export default BlogCard;