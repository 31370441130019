import React from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container, Typography, Box } from '@mui/material';
import getLPTheme from '../getLPTheme';
import HeroSection from '../components/blog/sections/HeroSection';
import AllPosts from '../components/blog/sections/AllPostsSection';
import RecentPosts from '../components/blog/sections/RecentSection';
import CTASection from '../components/CTASection';
import Footer from '../components/Footer';

const BlogPage = () => {
    const LPtheme = createTheme(getLPTheme('light'));
    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>Content to power your job search | Joblixx</title>
            </Helmet>
        <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
            <HeroSection />
            <Container maxWidth="lg">
                <RecentPosts />
            </Container>
            <CTASection />
            <Container maxWidth="lg">
                <AllPosts />
            </Container>
            <Footer />
        </Box>
        </ThemeProvider>
    );
};

export default BlogPage;